<template>
        <div class="single-service-cap mb-30 boxservicos">
            <div class="service-cap">
                <h4>
                    <router-link :to="`/produto/${produto.id}/${produto.slug}`">
                        {{ produto.titulo }}
                    </router-link>
                </h4>
                    
            </div>
            <div class="service-img">
                <img :src="'https://sistema.gouarts.com/uploads/' + produto.foto" class="img-fluid" :alt="produto.titulo">
            </div>
            <div class="service-cap">
                <p>{{ produto.resumo }}</p>
                <router-link class="more-btn" :to="`/produto/${produto.id}/${produto.slug}`">SAIBA MAIS <i class="ti-plus"></i></router-link>
            </div>
            
        </div>
</template>

<script>
    export default {
        props: ['produto']
    };
</script>
<style scoped>
.img-fluid {
    width: 370px!important;
    height: 268px!important;
}
</style>