<template>
    <div class="main-container">

        <Header />
        <div class="secoes_site">     
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1 v-for="categoria in lista_produtos.categorias" :key="categoria.id">Serviços: {{categoria.titulo}}</h1>
                                <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                  
        <div class="page-wrapper section-space--inner--10">
        <div class="services-area1">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6" v-for="produto in lista_produtos.produtos" :key="produto.id">
                    <Produtos :produto="produto" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Services Area End -->
        
            <!--Service section end-->
        </div>

        <Footer />

        <!-- <OffCanvasMobileMenu /> -->

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import axios from 'axios';
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb';
    import Produtos from '../components/Produtos';
    import Footer from '../components/Footer';
    //import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    export default {
        components: {
            Header,
            Breadcrumb,
            Produtos,
            Footer,
            //OffCanvasMobileMenu
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        data() {
            return {
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'Produtos',
                        active: true
                    }
                ],
                lista_produtos: [],
                categoria: []
            }
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - produtos`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
             content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }

    function doSomething() {
        var id = this.$route.params.id;  
        var slug =  this.$route.params.slug;  
       
        axios.get(`https://sistema.gouarts.com/api/produtos/`+id+`/`+slug+`/?cliente=vitoriainspecoes`).then(res => {
            this.lista_produtos = res.data;
            
        }).catch(err => {
            console.log(err);
        })
}
</script>
<style scoped>
.slider-area {
    height: 250px;    
}
.services-area1 {
    margin-top: 100px;
}
</style>

